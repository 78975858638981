import styled from 'styled-components';
import { Main as AppMain } from '../../../App.styled';

export const Main = styled(AppMain)`
  mask-image: linear-gradient(transparent, white 25px);
`;

export const Content = styled.div`
  padding: 100px clamp(20px, 5vw, 60px) 160px;
  text-align: center;
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Heading = styled.h1`
  color: ${(props) => props.theme.color.blue40};
  text-align: center;
  font-family: Ubuntu;
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (min-width: 1000px) {
    font-size: 52px;
    line-height: 64px; /* 123.077% */
  }
`;

export const Description = styled.p`
  color: ${(props) => props.theme.color.blue30};
  margin: 12px 0 32px;
  text-align: center;

  /* Body md */
  font-family: Cabin;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-wrap: pretty;
  line-height: 140%; /* 22.4px */
`;

export const TemplateButtons = styled.div`
  margin: 40px auto;
  text-wrap: balance;
  max-width: 62rem;
`;

export const Disclaimer = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.color.blue20};
  opacity: 0.75;
  padding-bottom: 2em;

  a {
    color: ${(props) => props.theme.color.blue20};
    text-decoration: underline;
    text-underline-offset: 2px;

    &:hover,
    &:active,
    &:focus {
      text-decoration-color: ${(props) => props.theme.color.blue};
    }
  }
`;

export const FooterDecorations = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
`;
