import styled from 'styled-components';
import { SectionWrapper } from '../VHForms/VHForms.styled';
import { Button } from '@virtidev/toolbox';

export const HeaderWrapper = styled(SectionWrapper)`
  background-color: ${(props) => props.theme.color.midnight5};
`;

export const StepBanner = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 22px;
`;

export const BackWrapper = styled.div`
  margin-left: auto;
`;

export const BackButton = styled(Button)`
  color: white;
  border-width: 2px;
`;
