import { Icon, Select } from '@virtidev/toolbox';
import { useSelectedTemplateStore } from '../../../stores/SelectedTemplateStore/SelectedTemplateStore';
import { useEffect, useState } from 'react';
import { useUserVHDataStore } from '../../../stores/UserVHDataStore/UserVHDataStore';
import * as Styled from './VHDataForm.styled';
import { OptionValue } from '@virtidev/toolbox/build/Select/Select.types';
import * as StyledForms from '../VHForms.styled';
import StepsBanner from '../../StepsBanner/StepsBanner';
import FormLabel from '../../FormLabel/FormLabel';
import Header from '../../Header/Header';
import { PersonalityTraitOptions } from '../VHTemplates.data';
import { getEditedFields, track } from '../../../utility/Tracking';
import { AppStage, getStep } from '../../../utility/AppStages';
import SectionHeader from '../../SectionHeader/SectionHeader';

const MAX_TRAITS_LENGTH = 255;
const getCharacterLength = (rsArray: OptionValue[]) => {
  return rsArray.map((item) => item.value).join(', ').length;
};

const minMaxTexts = {
  aggressiveness: {
    min: 'Passive',
    max: 'Aggressive',
  },
  chattiness: {
    min: 'Quiet',
    max: 'Chatty',
  },
  confidence: {
    min: 'Nervous',
    max: 'Confident',
  },
  openness: {
    min: 'Guarded',
    max: 'Open',
  },
};

const VHDataForm = ({
  onBack,
  onNextStage,
}: {
  onBack: () => void;
  onNextStage: () => void;
}) => {
  const selectedTemplate = useSelectedTemplateStore(
    (state) => state.selectedTemplate
  );
  const [inputDescription, setInputDescription] = useState('');
  const [personalityTraits, setPersonalityTraits] = useState<OptionValue[]>([]);
  const [personalitySliders, setPersonalitySliders] = useState({
    confidence: 0,
    aggressiveness: 0,
    openness: 0,
    chattiness: 0,
  });
  const setVHData = useUserVHDataStore((state) => state.setUserVHData);

  const handleContinue = () => {
    const vhData = {
      characterInformation: inputDescription,
      vhName: selectedTemplate?.vhName,
      personalityTraits: personalityTraits.map((trait) => trait.value),
      ...personalitySliders,
    };
    setVHData(vhData);
    track('step_completed', {
      step: getStep(AppStage.VH_EDITOR),
      scenario: selectedTemplate?.templateName,
      edited_fields: selectedTemplate
        ? getEditedFields(vhData, selectedTemplate)
        : [],
    });
    onNextStage();
  };

  useEffect(() => {
    if (!selectedTemplate) return;
    setInputDescription(selectedTemplate.characterHints.join('\n'));
    setPersonalityTraits(
      selectedTemplate.personalityTraits.map((trait) => ({
        label: trait,
        value: trait.toLowerCase(),
      }))
    );
    setPersonalitySliders({
      aggressiveness: selectedTemplate.aggressiveness,
      chattiness: selectedTemplate.chattiness,
      confidence: selectedTemplate.confidence,
      openness: selectedTemplate.openness,
    });
  }, [selectedTemplate]);

  const handlePersonalityRangeChange = (
    personalityKey: keyof typeof personalitySliders,
    value: number
  ) => {
    setPersonalitySliders({
      ...personalitySliders,
      [personalityKey]: value,
    });
  };

  const [traitsError, setTraitsError] = useState<string | null>(null);
  const handlePersonalityTraitsCreate = (
    newValue: string,
    newLabel?: string
  ) => {
    setTraitsError(null);
    const newTraits = [
      ...personalityTraits,
      { value: newValue, label: newLabel ?? newValue.toLowerCase() },
    ];
    const length = getCharacterLength(newTraits);
    if (length > MAX_TRAITS_LENGTH) {
      setTraitsError('Unable to add: length of existing traits too long');
      return;
    }
    setPersonalityTraits(newTraits);
  };

  const handlePersonalityTraitsAdd = (val: string, label: React.ReactNode) => {
    handlePersonalityTraitsCreate(val, label as string);
  };

  const handlePersonalityTraitRemove = (deletedValue: string) => {
    setTraitsError(null);
    const newVal = personalityTraits.filter(
      (personalityTrait) => personalityTrait.value !== deletedValue
    );
    setPersonalityTraits(newVal);
  };

  return (
    <>
      <Header />
      <Styled.Main id="main">
        <StyledForms.SectionWrapper>
          <StyledForms.Section>
            <header>
              <SectionHeader title="Customize your Virtual Human" />
              <StyledForms.HeaderContent>
                <Styled.HeaderInfo>
                  Now, edit the details. Try changing your Virtual human's
                  personal traits and background. Feel free to add as much
                  information as you like.
                </Styled.HeaderInfo>
              </StyledForms.HeaderContent>
            </header>
            <Styled.FormFields>
              <div>
                <StyledForms.Card>
                  <Styled.ImgWrapper>
                    <img
                      alt={selectedTemplate?.avatarAltTag}
                      src={selectedTemplate?.avatarSrc}
                    />
                  </Styled.ImgWrapper>
                  <Styled.ScenarioWrapper>
                    <Styled.ScenarioHeading>
                      {selectedTemplate?.templateName}
                    </Styled.ScenarioHeading>
                    <div>{selectedTemplate?.templateDescription}</div>
                  </Styled.ScenarioWrapper>
                </StyledForms.Card>
              </div>
              <Styled.InputsCol>
                <StyledForms.Card>
                  <Styled.SectionHeading>General</Styled.SectionHeading>
                  <StyledForms.CardContent>
                    <Styled.LockedInputs>
                      <Styled.NameWrapper>
                        <FormLabel
                          htmlFor="vh-name"
                          locked={true}
                          tooltipText="The name that will be used to reference the Virtual Human"
                        >
                          Name
                        </FormLabel>
                        <input
                          id="vh-name"
                          type="text"
                          disabled={true}
                          value={selectedTemplate?.vhName}
                        />
                      </Styled.NameWrapper>
                      <Styled.EnvironmentWrapper>
                        <FormLabel
                          htmlFor="vh-environment"
                          locked={true}
                          tooltipText="The 3D environment that the Virtual Human will be placed in"
                        >
                          Environment
                        </FormLabel>
                        <input
                          id="vh-environment"
                          type="text"
                          disabled={true}
                          value={selectedTemplate?.visualEnvironment}
                        />
                      </Styled.EnvironmentWrapper>
                      <Styled.LanguageWrapper>
                        <FormLabel
                          htmlFor="vh-language"
                          locked={true}
                          tooltipText="The language that the Virtual Human will converse in"
                        >
                          Language
                        </FormLabel>
                        <input
                          id="vh-language"
                          type="text"
                          disabled={true}
                          value={selectedTemplate?.visualLocale}
                        />
                      </Styled.LanguageWrapper>
                    </Styled.LockedInputs>
                  </StyledForms.CardContent>
                </StyledForms.Card>
                <StyledForms.Card>
                  <Styled.SectionHeading>Personal Traits</Styled.SectionHeading>
                  <StyledForms.CardContent>
                    <Styled.PersonalityInputs>
                      <Styled.PersonalityWrapper>
                        <FormLabel
                          htmlFor="traits"
                          tooltipText="A list of personality traits that will be used to shape the Virtual Human's responses. Try adding your own!"
                        >
                          Personality
                        </FormLabel>
                        <Select
                          id="traits"
                          options={PersonalityTraitOptions}
                          value={personalityTraits}
                          multi={true}
                          onCreateOption={handlePersonalityTraitsCreate}
                          onRemove={handlePersonalityTraitRemove}
                          onAdd={handlePersonalityTraitsAdd}
                          creatable
                        />
                        {traitsError && (
                          <Styled.TraitsWarning>
                            <Icon icon="alert" type="solid" /> {traitsError}
                          </Styled.TraitsWarning>
                        )}
                      </Styled.PersonalityWrapper>
                      <Styled.InformationWrapper>
                        <FormLabel
                          htmlFor="roleplay-text"
                          tooltipText="Any other information that you would like to use to shape the Virtual Human, such as their general history and background"
                        >
                          Additional information
                        </FormLabel>
                        <Styled.RoleplayTextArea
                          id="roleplay-text"
                          maxLength={512}
                          multiline={true}
                          value={inputDescription}
                          onChange={(e) => setInputDescription(e.target.value)}
                        />
                      </Styled.InformationWrapper>
                      <Styled.MoodWrapper>
                        <FormLabel>Mood</FormLabel>
                        <Styled.Sliders>
                          {Object.keys(personalitySliders).map(
                            (personalityKey) => {
                              const val =
                                personalitySliders[
                                  personalityKey as keyof typeof personalitySliders
                                ];
                              const percentageValue = ((val + 100) / 200) * 100;

                              return (
                                <Styled.Slider key={personalityKey}>
                                  <Styled.SliderLabelWrapper
                                    htmlFor={personalityKey}
                                  >
                                    <Styled.SliderLabel>
                                      {
                                        minMaxTexts[
                                          personalityKey as keyof typeof minMaxTexts
                                        ].min
                                      }
                                    </Styled.SliderLabel>
                                    <Styled.SliderLabel>
                                      {
                                        minMaxTexts[
                                          personalityKey as keyof typeof minMaxTexts
                                        ].max
                                      }
                                    </Styled.SliderLabel>
                                  </Styled.SliderLabelWrapper>
                                  <Styled.RangeInput
                                    id={personalityKey}
                                    type="range"
                                    step={50}
                                    min={-100}
                                    max={100}
                                    onChange={(e) =>
                                      handlePersonalityRangeChange(
                                        personalityKey as keyof typeof personalitySliders,
                                        +e.target.value
                                      )
                                    }
                                    $percentageValue={percentageValue}
                                    value={val}
                                  />
                                </Styled.Slider>
                              );
                            }
                          )}
                        </Styled.Sliders>
                      </Styled.MoodWrapper>
                    </Styled.PersonalityInputs>
                  </StyledForms.CardContent>
                </StyledForms.Card>
              </Styled.InputsCol>
            </Styled.FormFields>
          </StyledForms.Section>
        </StyledForms.SectionWrapper>
      </Styled.Main>
      <StepsBanner
        buttonText="Start"
        onContinue={handleContinue}
        onBack={onBack}
      />
    </>
  );
};

export default VHDataForm;
