import { PlayerTemplate } from '../../../../types/PlayerTemplate.type';
import { useCallback } from 'react';
import * as Styled from './TemplateSelectButton.styled';

const TemplateSelectButton = ({
  template,
  onTemplateSelect,
}: {
  template: PlayerTemplate;
  onTemplateSelect: (newTemplate: PlayerTemplate) => void;
}) => {
  const handleTemplateSelect = useCallback(() => {
    onTemplateSelect(template);
  }, [onTemplateSelect, template]);

  return (
    <Styled.TemplateButton
      key={template.templateName}
      onClick={handleTemplateSelect}
    >
      {template.templateName}
    </Styled.TemplateButton>
  );
};

export default TemplateSelectButton;
