import Header from '../Header/Header';
import * as Styled from './Intro.styled';
import AvatarVideoWebM from '../../assets/intro-avatar.webm';
import AvatarVideoBGWebM from '../../assets/bg.webm';
import CornerDecoration from '../CornerDecoration/CornerDecoration';

const Intro = ({ onNextStage }: { onNextStage: () => void }) => {
  return (
    <>
      <Styled.BgVideo autoPlay playsInline muted>
        <source src={AvatarVideoBGWebM} type="video/webm" />
      </Styled.BgVideo>
      <Header withButtons={false} limitWidth={false} />
      <Styled.Wrapper>
        <Styled.Main>
          <Styled.VideoWrapper className="VideoWrapper">
            <video autoPlay playsInline muted loop>
              <source src={AvatarVideoWebM} type="video/webm" />
            </video>
            <style type="text/css">
              {`
              @media (min-width: 1000px) {
                .VideoWrapper {
                  container-type: size;
                  container-name: headerVideoWrapper;
                }
                @container headerVideoWrapper (min-aspect-ratio: 4 / 3) {
                  .VideoWrapper img,
                  .VideoWrapper video {
                    object-position: center;
                    object-fit: contain;
                  }
                }
              }
            `}
            </style>
          </Styled.VideoWrapper>
          <Styled.Content>
            <Styled.Title>
              Hey, I’m Vera. Here’s a demo of our{' '}
              <Styled.NoWrap>AI-powered</Styled.NoWrap>{' '}
              <Styled.Highlight>Virtual Human</Styled.Highlight>{' '}
              <Styled.NoWrap>role-playing</Styled.NoWrap> experience.
            </Styled.Title>
            <Styled.Description>
              Create fully immersive end-to-end learning experiences that are
              proven to be 60% more effective than face-to-face scenarios.
            </Styled.Description>
            <Styled.CtaButton onClick={onNextStage} color="primary">
              Let’s chat
            </Styled.CtaButton>
          </Styled.Content>
        </Styled.Main>
        <Styled.FooterDecorations>
          <CornerDecoration variant={1} position={4} />
        </Styled.FooterDecorations>
      </Styled.Wrapper>
    </>
  );
};

export default Intro;
