import { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle, LightTheme } from '@virtidev/toolbox';
import ReactGA from 'react-ga4';
import { VirtiTheme } from '@virtidev/toolbox';
import VHDataForm from './components/VHForms/VHDataForm/VHDataForm';
import VHPlayer from './components/VHPlayer/VHPlayer';
import { AppStage, getStep } from './utility/AppStages';
import VHTemplateForm from './components/VHForms/VHTemplateForm/VHTemplateForm';
import CTAModal from './components/CTAModal/CTAModal';
import Header from './components/Header/Header';
import { MobileMessage, BookDemoWrapper } from './App.styled';
import { USE_ANALYTICS, track, trackPageView } from './utility/Tracking';
import DemoForm from './components/DemoForm/DemoForm';
import VHTemplates from './components/VHForms/VHTemplates.data';
import { PlayerTemplate } from './types/PlayerTemplate.type';
import { useUserVHDataStore } from './stores/UserVHDataStore/UserVHDataStore';
import { useSelectedTemplateStore } from './stores/SelectedTemplateStore/SelectedTemplateStore';
import * as Sentry from '@sentry/react';
import './App.css';
import './Sentry';
import '@virtidev/toolbox/build/styles.css';
import Intro from './components/Intro/Intro';

const params = new URL(window.document.location + '').searchParams;
const skipToTemplate = params.get('template');
let startTemplate: PlayerTemplate | undefined;
if (skipToTemplate) {
  startTemplate = VHTemplates.find(
    (template) => template.templateKey === skipToTemplate
  );
}

if (USE_ANALYTICS) {
  // Google Analytics
  if (import.meta.env.VITE_GA_TRACKING_ID) {
    ReactGA.initialize(import.meta.env.VITE_GA_TRACKING_ID);
  }
  // Hubspot Analytics
  const scriptUrl = 'https://js.hs-scripts.com/7277851.js';
  if (
    window.location.hostname === 'try.virti.com' &&
    !document.querySelector(`script[src="${scriptUrl}"]`)
  ) {
    const script = document.createElement('script');
    script.src = scriptUrl;
    script.async = true;
    document.body.appendChild(script);
  }
}

// cookie warning on prod (stops it showing up constantly on dev)
if (import.meta.env.PROD) {
  const script = document.createElement('script');
  script.setAttribute('type', 'text/javascript');
  script.setAttribute('charset', 'UTF-8');
  script.setAttribute(
    'src',
    'https://cdn.cookie-script.com/s/d97da55f9386d1113e26035229c65a4f.js'
  );
  document.body.appendChild(script);
}

declare module 'styled-components' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends VirtiTheme {}
}
function App() {
  const [showMobileMessage, _setShowMobileMessage] = useState(false);

  useEffect(() => {
    trackPageView();
  }, []);

  const setVHData = useUserVHDataStore((state) => state.setUserVHData);

  const setSelectedTemplate = useSelectedTemplateStore(
    (state) => state.setSelectedTemplate
  );
  useEffect(() => {
    if (startTemplate) {
      setVHData({
        ...startTemplate,
      });
      setSelectedTemplate(startTemplate);
      setStage(AppStage.VH_PLAYER);
    }
  }, [setSelectedTemplate, setVHData]);

  const [stage, setStage] = useState<AppStage>(AppStage.INTRO);

  const handleIntroNext = () => {
    setStage(AppStage.VH_TEMPLATE_SELECTOR);
  };

  const handleVHTemplateSelectorNext = () => {
    setStage(AppStage.VH_EDITOR);
  };
  const handleVHEditorNext = () => {
    setStage(AppStage.VH_PLAYER);
  };
  const handleVHEditorPrev = () => {
    setStage(AppStage.VH_TEMPLATE_SELECTOR);
  };

  const restart = () => {
    track('restart', { from_step: getStep(stage) });
    window.location.reload();
    // setStage(AppStage.VH_TEMPLATE_SELECTOR);
  };

  const mode = (function () {
    if (showMobileMessage) return 'mobileWarning';
    const params = new URLSearchParams(window.location.search);
    if (params.has('mode', 'book_demo')) return 'book_demo';
    return 'default';
  })();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has('sentry', 'crash')) {
      throw new Error('test error');
    } else if (params.has('sentry', 'send')) {
      Sentry.captureException('test exception');
    }
  }, []);

  useEffect(() => {
    if (mode === 'default') {
      track('step_view', { step: getStep(stage) });
    }
  }, [stage, mode]);

  return (
    <ThemeProvider theme={LightTheme}>
      <GlobalStyle $reset />
      {mode == 'mobileWarning' && (
        <div>
          <Header />
          <MobileMessage>
            If you wish to try out the Virti Virtual Human please visit this
            page on a desktop computer. We support Virtual Humans via our mobile
            app but this demo is only available for web. Thank you!
          </MobileMessage>
        </div>
      )}
      {mode == 'default' && (
        <>
          {stage === AppStage.INTRO && <Intro onNextStage={handleIntroNext} />}
          {stage === AppStage.VH_TEMPLATE_SELECTOR && (
            <VHTemplateForm onNextStage={handleVHTemplateSelectorNext} />
          )}
          {stage === AppStage.VH_EDITOR && (
            <VHDataForm
              onBack={handleVHEditorPrev}
              onNextStage={handleVHEditorNext}
            />
          )}
          {stage === AppStage.VH_PLAYER && <VHPlayer onRestart={restart} />}
        </>
      )}
      {mode == 'book_demo' && (
        <BookDemoWrapper>
          <DemoForm />
        </BookDemoWrapper>
      )}
      <CTAModal onRestart={restart} />
    </ThemeProvider>
  );
}

export default App;
