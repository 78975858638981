import styled, { css } from 'styled-components';

export const Main = styled.main`
  flex: 1;
  background-color: ${(props) => props.theme.color.midnight};
  position: relative;
  overflow: auto;
`;

export const TryHeader = styled.h1`
  margin-bottom: 1rem;
`;

export const MobileMessage = styled.div`
  text-align: center;
  max-width: 800px;
  margin: auto;
  margin-top: 2rem;
`;

export const BookDemoWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const linkCss = css`
  color: ${(props) => props.theme.color.midnight};
  text-decoration: underline;
  text-underline-offset: 2px;

  &:hover,
  &:active,
  &:focus {
    text-decoration-color: ${(props) => props.theme.color.blue10};
  }
`;
