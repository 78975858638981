import * as Styled from './SectionHeader.styled';

const SectionHeader = ({ title }: { title: string }) => {
  return (
    <Styled.SectionHeaderWrapper>
      <Styled.SectionHeading>{title}</Styled.SectionHeading>
    </Styled.SectionHeaderWrapper>
  );
};

export default SectionHeader;
