import styled from 'styled-components';

export const SectionHeaderWrapper = styled.div``;

export const SectionHeading = styled.h1`
  color: ${({ theme }) => theme.color.blue40};
  font-family: Ubuntu;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 56px 0 8px;
`;
