import { useSelectedTemplateStore } from '../../../stores/SelectedTemplateStore/SelectedTemplateStore';
import { useCallback } from 'react';
import { useUserVHDataStore } from '../../../stores/UserVHDataStore/UserVHDataStore';
import * as Styled from './VHTemplateForm.styled';
import { PlayerTemplate } from '../../../types/PlayerTemplate.type';
import { VisibleVHTemplates } from '../VHTemplates.data';
import TemplateSelectButton from './TemplateSelectButton/TemplateSelectButton';
import Header from '../../Header/Header';
import { track } from '../../../utility/Tracking';
import { AppStage, getStep } from '../../../utility/AppStages';
import CornerDecoration from '../../CornerDecoration/CornerDecoration';

const VHTemplateForm = ({ onNextStage }: { onNextStage: () => void }) => {
  const setSelectedTemplate = useSelectedTemplateStore(
    (state) => state.setSelectedTemplate
  );
  const setVHData = useUserVHDataStore((state) => state.setUserVHData);

  const handleTemplateSelect = useCallback(
    (template: PlayerTemplate) => {
      setSelectedTemplate(template);
      setVHData({
        characterInformation: template.characterHints.join('\n'),
        vhName: template.vhName,
        scenarioObjective: template.scenarioObjective,
        greeting: template.greeting ?? '',
        openness: template.openness,
        confidence: template.confidence,
        aggressiveness: template.aggressiveness,
        chattiness: template.chattiness,
        personalityTraits: template.personalityTraits,
        background: template.background,
      });
      track('step_completed', {
        step: getStep(AppStage.VH_TEMPLATE_SELECTOR),
        scenario: template.templateName,
      });
      onNextStage();
    },
    [onNextStage, setSelectedTemplate, setVHData]
  );

  return (
    <>
      <Header withButtons={false} limitWidth={false} />
      <Styled.Main id="main">
        <Styled.Content>
          <Styled.Heading>Choose your scenario</Styled.Heading>
          <Styled.Description>
            Start with a role-play scenario that best suits your area of
            interest.
            <br /> You can customize your chosen scenario at the next step.
          </Styled.Description>
          <Styled.TemplateButtons>
            {VisibleVHTemplates.map((template) => (
              <TemplateSelectButton
                key={template.templateName}
                onTemplateSelect={handleTemplateSelect}
                template={template}
              />
            ))}
          </Styled.TemplateButtons>
          <Styled.Disclaimer>
            Use of this demo is subject to Virti’s{' '}
            <a
              href="https://www.virti.com/virti-privacy-policy-website-and-direct-marketing/"
              target="_blank"
              aria-label="Opens a new tab"
            >
              privacy policy
            </a>
            .
          </Styled.Disclaimer>
        </Styled.Content>
        <Styled.FooterDecorations>
          <CornerDecoration variant={3} position={4} />
          <CornerDecoration variant={4} position={3} />
        </Styled.FooterDecorations>
      </Styled.Main>
      <CornerDecoration variant={2} position={2} />
    </>
  );
};

export default VHTemplateForm;
