import styled, { css } from 'styled-components';

export const CornerDecorationWrapper = styled.div<{
  $position: number;
}>`
  transform: scale(0.61349693);

  @media (min-width: 800px) and (min-height: 900px) {
    transform: scale(1);
  }

  ${(props) =>
    !!props.$position &&
    css`
      position: absolute;
      z-index: 1;
      pointer-events: none;
    `}

  ${(props) => {
    switch (props.$position) {
      case 1:
        return css`
          top: 0;
          left: 0;
          transform-origin: top left;
        `;
      case 2:
        return css`
          top: 0;
          right: 0;
          transform-origin: top right;
        `;
      case 3:
        return css`
          bottom: 0;
          right: 0;
          transform-origin: bottom right;
        `;
      case 4:
        return css`
          bottom: 0;
          left: 0;
          transform-origin: bottom left;
        `;
    }
  }}
`;
