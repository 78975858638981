import { useCallback, useState } from 'react';
import { Button, Icon } from '@virtidev/toolbox';
import * as Styled from './Header.styled';
import { useIsWidthGte } from '../../utility/hooks/LayoutHooks';
import MobileMenu from './MobileMenu/MobileMenu';
import { getModeUrl } from '../../utility/RouteUtils';
import { trackCtaClick } from '../../utility/Tracking';

const Header = ({
  onRestart,
  withButtons = true,
  limitWidth = true,
}: {
  onRestart?: () => void;
  withButtons?: boolean;
  limitWidth?: boolean;
}) => {
  const isMobileLayout = !useIsWidthGte(660);
  const verboseButtons = useIsWidthGte(870);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const onMobileMenuClick = useCallback(() => {
    setMobileMenuOpen(!mobileMenuOpen);
  }, [mobileMenuOpen]);

  const trackTrialClick = useCallback(
    () => trackCtaClick('free_trial', 'header'),
    []
  );
  const trackDemoClick = useCallback(
    () => trackCtaClick('book_demo', 'header'),
    []
  );
  const handleLogoClick = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <Styled.HeaderWrapper>
      <Styled.SkipLink href="#main">Skip to main content</Styled.SkipLink>
      <Styled.HeaderSection $limitWidth={limitWidth}>
        <Styled.Header $mobileLayout={isMobileLayout}>
          <Styled.VirtiWrapper>
            <Styled.VirtiLink onClick={handleLogoClick}>
              <img src="virti-logo-light.svg" alt="Virti logo" />
            </Styled.VirtiLink>
          </Styled.VirtiWrapper>
          {withButtons && (
            <Styled.CTAButtons>
              {!isMobileLayout && (
                <>
                  {onRestart && (
                    <Button
                      color="transparent-contrast"
                      type="submit"
                      onClick={onRestart}
                      icon="loop"
                    >
                      {verboseButtons ? 'Try another scenario' : 'Start over'}
                    </Button>
                  )}
                  <Styled.TryFreeButton
                    as="a"
                    color="turquoise"
                    href="https://app.virti.com/register"
                    target="_blank"
                    onClick={trackTrialClick}
                    aria-label="Opens a new tab"
                  >
                    {verboseButtons ? 'Try free for 14 days' : 'Free trial'}
                  </Styled.TryFreeButton>
                  <Button
                    design="outline"
                    color="primary"
                    as="a"
                    href={getModeUrl('book_demo')}
                    target="_blank"
                    onClick={trackDemoClick}
                    aria-label="Opens a new tab"
                  >
                    Book a demo
                  </Button>
                </>
              )}
              {isMobileLayout && (
                <div>
                  <Styled.MenuButton
                    aria-label={mobileMenuOpen ? 'Close menu' : 'Open Menu'}
                    onClick={onMobileMenuClick}
                  >
                    {mobileMenuOpen ? (
                      <Icon icon="cross" size="18px" />
                    ) : (
                      <Icon icon="menu" size="24px" />
                    )}
                  </Styled.MenuButton>
                  <MobileMenu onRestart={onRestart} isOpen={mobileMenuOpen} />
                </div>
              )}
            </Styled.CTAButtons>
          )}
        </Styled.Header>
      </Styled.HeaderSection>
    </Styled.HeaderWrapper>
  );
};

export default Header;
