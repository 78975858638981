import * as Styled from './CornerDecoration.styled';

const CornerDecoration = ({
  variant = 1,
  position = 0,
}: {
  variant: number;
  position?: number;
}) => {
  return (
    <Styled.CornerDecorationWrapper $position={position}>
      {variant === 1 && (
        <svg
          fill="none"
          height="171"
          viewBox="0 0 171 171"
          width="171"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="#fafaff">
            <path d="m0 0h57v57h-57z" transform="matrix(-1 0 0 1 57 114)" />
            <path d="m0 0h57v57h-57z" transform="matrix(-1 0 0 1 114 57)" />
            <path d="m0 0h57v57h-57z" transform="matrix(-1 0 0 1 57 0)" />
            <path d="m0 0h57v57h-57z" transform="matrix(-1 0 0 1 171 114)" />
            <path d="m0 0h57v57h-57z" transform="matrix(-1 0 0 1 114 114)" />
          </g>
        </svg>
      )}
      {variant === 2 && (
        <svg
          fill="none"
          height="129"
          viewBox="0 0 213 129"
          width="213"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m128.688 0h84.3125v84.3125h-84.3125z" fill="#f6dee7" />
          <g fill="#dd6b9c">
            <path d="m0 84.3125h44.375v44.375h-44.375z" />
            <path d="m44.375 0h84.3125v84.3125h-84.3125z" />
          </g>
        </svg>
      )}
      {variant === 3 && (
        <svg
          fill="none"
          height="246"
          viewBox="0 0 164 246"
          width="164"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="#fafaff">
            <path d="M163.631,246.001L0,246.001L0,82.371L81.815,82.371L81.815,164.186L163.631,164.186L163.631,246.001Z" />
            <rect x="81.815" y="0.555" width="81.815" height="81.815" />
          </g>
        </svg>
      )}
      {variant === 4 && (
        <svg
          fill="none"
          height="182"
          viewBox="0 0 234 182"
          width="234"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m117 65h117v117h-117z" fill="#d6e1ee" />
          <g fill="#97c5fe">
            <path d="m0 65h117v117h-117z" />
            <path d="m0 0h65v65h-65z" />
          </g>
        </svg>
      )}
    </Styled.CornerDecorationWrapper>
  );
};

export default CornerDecoration;
