import { Button } from '@virtidev/toolbox';
import * as Styled from './StepsBanner.styled';
import * as StyledForms from '../VHForms/VHForms.styled';

const StepsBanner = ({
  buttonText,
  onContinue,
  onBack,
}: {
  buttonText: string;
  onContinue: () => void;
  onBack?: () => void;
}) => {
  return (
    <Styled.HeaderWrapper>
      <StyledForms.Section>
        <Styled.StepBanner>
          {onBack && (
            <Styled.BackWrapper>
              <Styled.BackButton
                color="secondary"
                type="submit"
                onClick={onBack}
              >
                Previous
              </Styled.BackButton>
            </Styled.BackWrapper>
          )}
          <Button color="primary" type="submit" onClick={onContinue}>
            {buttonText}
          </Button>
        </Styled.StepBanner>
      </StyledForms.Section>
    </Styled.HeaderWrapper>
  );
};

export default StepsBanner;
