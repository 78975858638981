import { Button } from '@virtidev/toolbox';
import styled from 'styled-components';

export const TemplateButton = styled(Button)`
  color: white;
  font-family: Cabin;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 14px;
  border: 2px solid rgba(151, 197, 254, 0.5);
  background: rgba(214, 225, 238, 0.1);
  margin: 8px;
`;
