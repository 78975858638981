import { Button } from '@virtidev/toolbox';
import styled from 'styled-components';
import { CornerDecorationWrapper } from '../CornerDecoration/CornerDecoration.styled';

export const Wrapper = styled.div`
  min-height: 100%;
  overflow: auto;
  mask-image: linear-gradient(transparent, white 25px);
`;

export const Main = styled.main`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  position: relative;
  align-content: center;
  min-height: 100%;

  @media (min-width: 1000px) {
    grid-template-columns: 1fr 1fr;
    align-content: stretch;
  }
`;

export const BgVideo = styled.video`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  object-fit: cover;
`;

export const Content = styled.div`
  padding: 0 clamp(20px, 13vw, 120px) 48px;
  color: white;
  align-self: center;
  z-index: 1;
  text-align: center;

  @media (min-width: 1000px) {
    padding: 60px 0 100px clamp(40px, 10vw, 120px);
    margin-right: -20%;
    text-align: left;
    order: 0;
  }
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.color.blue40};
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 400;
  text-wrap: pretty;

  font-size: 36px;
  line-height: 44px; /* 122.222% */

  @media (min-width: 1000px) {
    font-size: 52px;
    line-height: 64px; /* 123.077% */
  }
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.color.blue30};
  font-family: Cabin;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  max-width: 21.625em;
  text-wrap: pretty;
  margin: 28px auto;

  @media (min-width: 1000px) {
    margin-left: 0;
  }
`;

export const Highlight = styled.span`
  background-color: ${({ theme }) => theme.color.pink10};
  color: ${({ theme }) => theme.color.midnight};
  padding: 0 0.18em;
  white-space: nowrap;
`;

export const CtaButton = styled(Button)`
  padding: 20px 48px;
  border-radius: 100px;

  @media (max-width: 400px) {
    display: block;
    width: 100%;
  }
`;

export const VideoWrapper = styled.div`
  @media (max-width: 999.999px) {
    position: relative;
    margin-bottom: -143px;

    img,
    video {
      width: 100%;
      max-width: 800px;
      height: auto;
      aspect-ratio: auto;
      margin: 0 auto;
      object-fit: cover;
      object-position: 43% 50%;
    }

    mask-image: linear-gradient(white 60%, transparent);
  }
  @media (max-width: 530px) {
    img,
    video {
      width: 100%;
      max-width: 530px;
      height: auto;
      aspect-ratio: 2 / 3;
    }
    margin-bottom: -35vw;
  }

  @media (min-width: 1000px) {
    justify-self: stretch;
    position: relative;
    order: 1;

    &::after {
      content: none;
    }

    img,
    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: left top;
    }
  }
`;

export const NoWrap = styled.span`
  white-space: nowrap;
`;

export const FooterDecorations = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;

  @media (max-width: 999.999px) {
    ${CornerDecorationWrapper} {
      display: none;
    }
  }
`;
